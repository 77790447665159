import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <Navigation />
      <h1>🤓</h1>
    </div>
  );
}

export default LandingPage;
