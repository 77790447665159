import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import DeathRoll from './components/DeathRoll/DeathRoll';
import LogIn from './components/LogIn/LogIn';
import './index.css';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/deathroll" element={<DeathRoll />} />
      <Route path="/login" element={<LogIn />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
