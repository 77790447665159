
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

export function deathRollGame(startingNumber, namesList) {
    
    let rollNumber = startingNumber;
    let gameResults = [];
    let count = 0;

    while(rollNumber != 1) {
        let oldNumber = rollNumber;
        rollNumber = getRandomInt(1, rollNumber);
        let fillNumbers = []
        for (let i = 0; i < 10; i++){
            fillNumbers.push(getRandomInt(rollNumber, oldNumber));
        }
        fillNumbers.sort();
        gameResults.push([
            rollNumber, 
            namesList[count % namesList.length], 
            fillNumbers
        ])
        count++;
    }
    
    return gameResults;
}

