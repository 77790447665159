import React from 'react';
import { Link } from 'react-router-dom';
import './Back.css';

function Back() {
  return (
    <nav className="back">
      <Link className="back-button" to="/">Back</Link>
    </nav>
  );
}

export default Back;
