import React from 'react';

function SettingsTab({ startingNumber, handleStartingNumberChange, playerNames, handleKeyUp, handleRemove, handleAddPlayerClick }) {
    return (
        <div className="tab-content-settings">
            <label htmlFor="starting-number">Starting Number:</label>
            <div>
                <input
                    className='number-input'
                    type="number"
                    id="starting-number-manual"
                    name="starting-number-manual"
                    min="1"
                    max="10000000"
                    placeholder="1000000"
                    autoComplete='off'
                    value={startingNumber}
                    onChange={handleStartingNumberChange}
                />
            </div>
            <label htmlFor="player-names">Player names:</label>
            <div>
                <div className="player-names-input-container">
                    <input
                        className="text-input"
                        type="text"
                        id="player-names"
                        name="player-names"
                        autoComplete="off"
                        onKeyUp={handleKeyUp}
                    />
                    <button className="add-button" onClick={handleAddPlayerClick}>
                        Add
                    </button>
                </div>
                {playerNames.map((name, index) => (
                    <div key={index}>
                        <button className="remove-button" onClick={() => handleRemove(index)}>
                            ❌
                        </button>
                        {name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SettingsTab;
