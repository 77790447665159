import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

function Navigation() {
  return (
    <nav className="navigation">
      <Link className="navigation-button" to="/DeathRoll">Death Roll</Link>
    </nav>
  );
}

export default Navigation;
