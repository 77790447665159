import React, { useState, useRef } from 'react';
import Back from '../../components/Back/Back';
import Settings from './components/Settings';
import { deathRollGame } from './DeathRollGame'
import './DeathRoll.css';

function DeathRoll() {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const [startingNumber, setStartingNumber] = useState(1000000);
  const [playerNames, setPlayerNames] = useState([]);

  const [displayName, setDisplayName] = useState('');
  const [displayNumber, setDisplayNumber] = useState();
  const [gameValue, setGameValue] = useState([]);
  const [gameHistory, setGameHistory] = useState();
  const gameHistoryRef = useRef([]);

  const toggleHistory = () => {
    setGameHistory(gameHistoryRef.current);
    setIsHistoryOpen(!isHistoryOpen);
  }
  const toggleSettings = () => {
    if (!isSettingsOpen) {
      setIsSettingsOpen(true);
    }
  };

  const handleStartingNumberChange = (event) => {
    setStartingNumber(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      const name = event.target.value;
      setPlayerNames([...playerNames, name]);
      event.target.value = "";
    }
  };

  const handleRemove = (index) => {
    const newNames = [...playerNames];
    newNames.splice(index, 1);
    setPlayerNames(newNames);
  };

  const handleStartClick = () => {
    if (playerNames.length === 0) {
      alert("Please set player names in the settings");
    } else {
      setGameStarted(true);
      const newResults = deathRollGame(startingNumber, playerNames)
      setGameValue(newResults);
    }
  };

  const handleRollClick = () => {
    const firstElement = gameValue.shift();
    const number = firstElement[0];
    const name = firstElement[1];
    
    setDisplayNumber(number);
    setDisplayName(name);

    if (number === 1) {
      gameHistoryRef.current.push(name)
    }
  };

  const handleCloseClick = () => {
    setIsSettingsOpen(false);
  };

  const handleAddPlayerClick = () => {
    const name = document.getElementById('player-names').value.trim();
    if (name) {
      setPlayerNames([...playerNames, name]);
      document.getElementById('player-names').value = '';
    }
  };

  const handleResetClick = () => {
    setGameStarted(false);
    setGameValue([]);
    setGameHistory([]);
    setDisplayName('');
    setDisplayNumber(startingNumber);
  }; 

  return (
    <body>
      <title>Death Roll Game</title>
      <div className="death-roll">
        <Back />
        {!gameStarted && (
          <><h1>Death Roll Drinking Game</h1><div className="start-container">
            <button className="start-button" onClick={handleStartClick}>Start</button>
          </div></>
        )}
        {gameStarted && (
          <>
            <h1>{displayName}</h1>
            <h1>{displayNumber}</h1>
            {displayNumber === 1 ? (
              <button className="reset-button" onClick={handleResetClick}>Reset</button>
            ) : (
              <button className="roll-button" onClick={handleRollClick}>Roll</button>
            )}
          </>
        )}
        <div className="tabs">
          <div
            className={`tab ${isSettingsOpen ? 'open' : ''}`}
            onClick={toggleSettings}
          >
            <h2>Settings</h2>
            {isSettingsOpen && (
              <><Settings
                startingNumber={startingNumber}
                playerNames={playerNames}
                handleStartingNumberChange={handleStartingNumberChange}
                handleKeyUp={handleKeyUp}
                handleRemove={handleRemove}
                handleAddPlayerClick={handleAddPlayerClick} />
                <div className="close-container">
                  <button className="close-button" onClick={handleCloseClick}>Close</button>
                </div>
              </>
            )}
          </div>
          <div className={`tab ${isHistoryOpen ? 'open' : ''}`} onClick={toggleHistory}>
            <h2>History</h2>
            {isHistoryOpen &&
              <div className="history">
                <div>
                  {gameHistory.map((name, index) => (
                    <div key={index} className='history-text'>
                      {name}
                    </div>
                  ))}
                </div>
              </div>}
          </div>
        </div>
      </div>
    </body>
  );
}

export default DeathRoll;
